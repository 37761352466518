define(['app', 'quiz'], (app, quiz) => {

  const foundationFinderMultiBrand = () => {
    const component = {};

    component.config = {
      channels: {
        selectSkips: 'quiz/selectSkips'
      }
    };

    component.selectSkips = () => {
      app.publish(component.config.channels.selectSkips, true);
    }

    component.init = (element) => {
      component.element = element;
      component.selectSkips();
    };

    return component;
  };

  return foundationFinderMultiBrand;
});
